<template>
    <div id="activity-file">
        <div class="content">
            <div class="col">
                <div class="row">
                    <span class="hint">憑證附件:</span>
                    <div class="value">
                        <div :class="{'btn-file': true, 'ok': file}" @click="takeFile">{{file ? file.name : '选择圖片文件≤5M'}}</div>
                    </div>
                </div>
                <div class="row">
                    <span class="hint">付款時間:</span>
                    <div class="value">
                        <input type="date" v-model="date">
                        <input type="time" v-model="time">
                    </div>
                </div>
            </div>
            <div class="operate">
                <div class="btn-cancel" @click="callback">取消</div>
                <div class="btn-ok" @click="submit"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '确定'}}</div>
            </div>
        </div>
        <input type="file" ref="file" style="display: none;" @change="onFileChange">
    </div>
</template>

<script>
export default {
    props: {
        callback: Function,
        activityId: null,
    },
    data() {
        return {
            date: null,
            time: null,
            file: null,
            loading: false,
        }
    },
    methods: {
        takeFile: function(){
            this.$refs.file.click()
        },
        onFileChange: function() {
            var file = this.$refs.file.files[0]
            if(file.size > 1024*1024*5) {
                this._toast.warning(this, '附件不能＞5M')
                this.$refs.file.value = ''
                return
            }
            this.file = file
        },
        submit: function(){
            if(!this.file) {
                this._toast.warning(this, '請選擇附件')
                return
            }
            if(!this.date || !this.time) {
                this._toast.warning(this, '請選擇付款時間')
                return
            }
            this.loading = true
            var formData = new FormData()
            formData.append("files", this.file);
            this.$store.dispatch('uploadImage', {imgCategory: 7, form: formData})
            .then((rs)=>{
                var filePath = rs[0]
                this.$store.dispatch('addCertificate', {
                    activityregid: this.activityId,
                    attachment: filePath,
                    paytime: `${this.date} ${this.time}`
                })
                .then((rs) => {
                    this.loading = false
                    this._toast.success(this, '憑證上傳成功')
                    setTimeout(() => {
                        this.callback && this.callback()
                    }, 1500)
                }).catch((error) => {
                    this.loading = false
                    this._toast.warning(this, error || '操作失敗')
                })
            }).catch(error => {
                this.loading = false
                this._toast.warning(this, error || '附件上傳失敗')
                this.$refs.file = ""
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #activity-file {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .3);
    }

    #activity-file > .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 360px;
        background-color: #fff;
        width: 90%;
        box-shadow: 12px;
        display: flex;
        flex-direction: column;
        padding: 24px 12px;
    }

    #activity-file > .content > .col {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #activity-file > .content > .operate {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 28px;
        font-weight: bold;
        color: #333;
        justify-content: center;
        margin-top: 24px;
    }

     #activity-file > .content > .operate > .btn-ok {
        min-width: 150px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 50px;
        padding: 0 8px;
     }

    #activity-file > .content > .operate > .btn-cancel {
        min-width: 150px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #e8e8e8;
        color: #999;
        border-radius: 4px;
        cursor: pointer;
    }

    #activity-file > .content .row {
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;
        margin-top: 24px;
    }
    #activity-file > .content .row .hint {
        font-size: 26px;
        color: #333;
        font-weight: bold;
        white-space: nowrap;
    }
    #activity-file > .content .row .btn-file {
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        flex: 1;
        padding: 0 12px;
        cursor: pointer;
        color: #999;
        overflow: hidden;
        box-sizing: border-box;
    }
    #activity-file > .content .row .btn-file.ok {
        color: royalblue;
    }
    #activity-file > .content .row .value {
        display: flex;
        flex: 1;
        flex-direction: row;
        height: 60px;
        line-height: 60px;
        margin-left: 24px;
    }
    #activity-file > .content .row .value > input {
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        margin-right: 12px;
        flex: 1;
        padding: 0 12px;
    }
    #activity-file > .content .row .value > input:nth-child(2) {
        margin-right: 0px;
    }
    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid royalblue;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: royalblue transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>