<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="activity-list" style="min-height: 100vh;" ref="listView">
			<div :class="{'activity-item': true, [item._levelText]: true}" v-for="item in dataList" :key="item.activityid">
				<div class="row">
					<span class="activity-name" v-text="item.associationname"></span>
					<span class="status" v-text="item._statusText"></span>
				</div>
				<h2 class="title" v-text="item.title"></h2>
				<div class="time" v-text="item.starttime + '~' + item.endtime"></div>
				<div class="operate">
					<span class="code" v-text="'參與碼: ' + item.regcode"></span>
					<div class="btn btn-detail">
						<img src="../../../../assets/imgs/mobile/user_center/img_huod@2x.png" alt="" class="icon-detail">
						<span class="hint">活動詳情</span>
					</div>
					<div class="btn btn-upload" @click="showUpload(item)">
						<img src="../../../../assets/imgs/mobile/user_center/img_pingz@2x.png" alt="" class="icon-upload">
						<span class="hint">上傳憑證</span>
					</div>
					<a v-if="item.regAttachment" :href="item.regAttachment" target="_blank" class="preview-img">
						<img :src="item.regAttachment" alt="憑證附件">
					</a>
				</div>
			</div>
			<div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
        <activity-file v-if="isUpload" :callback="onUploadResult" :activityId="mActivity.activityregid"/>
	</van-pull-refresh>
</template>

<script>
    import ActivityFile from '@/layouts/MobileActivityFile.vue'
	import Loading from '@/layouts/Loading.vue'
	import my_activity from '@/views/mixins/my_activity'
	export default {
		mixins: [
			my_activity
		],
		components: {
			Loading,
			ActivityFile,
		},
		data: function(){
			return {
                isUpload: false,
				style: '',
				mActivity: null,
				loading: true,
				isRefresh: false,
				isLoadMore: false,
				isLoadFinished: false,
				pageNo: 1,
				pageSize: 10,
				maxPage: 1,
				dataList: [],
				userInfo: this.$store.getters.userInfo
			}
		},
		methods: {
			showUpload: function(activity){
                this.mActivity = activity
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
                this.isUpload = true
            },
            onUploadResult: function(){
                this.isUpload = false
				document.body.setAttribute('style', this.style)
            },
            onRefresh: function() {
                this.pageNo = 1
                this.loading = true
                this.loadData(this.userInfo.doctorglobaluserid, this.pageNo, this.pageSize, (data, error)=>{
					this.loading = false
					this.isRefresh = false
                    this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error)
							this.isLoadFinished = true
					}
					if(error)
						this._toast.warning(this, error)
                })                
			},
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData(this.userInfo.doctorglobaluserid, this.pageNo, this.pageSize, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
        },
		created: function(){
			if(! this.userInfo) {
                this.$router.push('/')
                return
            }
            if(! this.userInfo.doctorglobaluserid) {
                this.$router.push('/user')
                return
			}
			window.addEventListener('scroll', this.handleScroll);
			this.onRefresh()
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	.activity-list {
		display: flex;
		flex-direction: column;
		padding-top: 16px;
	}

	.activity-list > .activity-item {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		border-radius: 16px;
		box-shadow:0px 2px 8px 0px rgba(232,234,243,1);
		margin: 0px 24px 24px 24px;
		padding: 0px 24px;
	}

	.activity-list > .activity-item .row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.activity-list > .activity-item .activity-name {
		font-size: 26px;
		color: #969696;
		font-weight: 400;
		line-height: 32px;
		margin-top: 40px;
	}

	.activity-list > .activity-item .status {
		padding: 0px 14px;
		height: 32px;
		line-height: 32px;
		margin-top: 40px;
		border-radius: 16px;
		background-color: #C5E6C7;
		color: #469550;
		font-size: 24px;
		font-weight: bold;
	}

	.activity-list > .activity-item.level1 .status {
		background-color: #EAF1F7;
		color: #3E607C;
	}

	.activity-list > .activity-item.level2 .status {
		background-color: #EDEDED;
		color: #231F20;
	}

	.activity-list > .activity-item.level3 .status {
		background-color: #EDEDED;
		color: #969696;
	}

	.activity-list > .activity-item .title {
		color: #231F20;
		font-size: 34px;
		font-weight: bold;
		line-height: 40px;
		margin-top: 26px;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.activity-list > .activity-item .time {
		color: #969696;
		font-weight: 400;
		font-size: 24px;
		line-height: 23px;
		margin-top: 24px;
	}

	.activity-list > .activity-item.level3 .activity-name,
	.activity-list > .activity-item.level3 .title,
	.activity-list > .activity-item.level3 .time {
		color: #969696;
	}

	.activity-list > .activity-item .operate {
		display: flex;
		flex-direction: row;
		height: 117px;
		align-items: center;
		justify-content: space-between;
	}

	.activity-list > .activity-item .operate .code {
		color: #231F20;
		font-size: 24px;
		font-weight: 400;
	}

	.activity-list > .activity-item .operate .btn {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.activity-list > .activity-item .operate .preview-img {
		width: 48px;
		height: 36px;
	}

	.activity-list > .activity-item .operate .preview-img > img {
		width: 100%;
		height: 100%;
	}

	.activity-list > .activity-item .operate .btn > .icon-detail {
		width: 20px;
		height: 23px;
		vertical-align: middle;
	}

	.activity-list > .activity-item .operate .btn > .icon-upload {
		width: 25px;
		height: 22px;
		vertical-align: middle;
	}

	.activity-list > .activity-item .operate .btn > .hint {
		color: #231F20;
		font-size: 24px;
		font-weight: 400;
		margin-left: 15px;
	}


	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>
